<script>
	import Layout from '../../layouts/main'
	import appConfig from '@/app.config'
	import PageHeader from '@/components/page-header'
	import Vue from 'vue'
	import axios from 'axios'
	import VueAxios from 'vue-axios'
	import VueTimepicker from 'vue2-timepicker'
	import 'vue2-timepicker/dist/VueTimepicker.css'
	import VueGoogleAutocomplete from 'vue-google-autocomplete'
	import helperFile from '@/helpers/helper'
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import { isTimeDefaultValue } from '../../../validators/validators'

	Vue.use(VueAxios, axios)
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

	// window.addEventListener("beforeunload", () => {
	// 	localStorage.removeItem('corporateId')
	// 	localStorage.removeItem('isDashboardView')
	// })


	export default {
		middleware: 'authentication',
		page: {
			title: '',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			VueTimepicker,
			VueGoogleAutocomplete,
		},
		validations: {
			corporateBranchName: {
				required,
			},
			corporateBranchAddress: {
				required,
			},
			shiftName: {
				required,
			},
			setAllDayShift: {
				required: requiredIf((isTimeForAll) => {
					return isTimeForAll
				}),
				start: {
					required,
					isTimeDefaultValue,
				},
				end: {
					required,
					isTimeDefaultValue,
				},
			},
		},
		data: function () {
			return {
				title: 'Corporate Branch',
				isSaturdayOff: true,
				isSundayOff: true,
				loading: false,
				isTimeForAll: false,
				shiftName: null,
				corporateBranchName: '',
				corporateBranchAddress: '',
				corporateBranchLocation: null,
				corporateId: '',
				branchId: 0,
				submitted: false,
				checkboxOption: ['Set time for all week'],
				shifts: [
					{
						shiftId: null,
						shiftDay: 'Monday',
						start: 'hh:mm A',
						end: 'hh:mm A',
					},
					{
						shiftId: null,
						shiftDay: 'Tuesday',
						start: 'hh:mm A',
						end: 'hh:mm A',
					},
					{
						shiftId: null,
						shiftDay: 'Wednesday',
						start: 'hh:mm A',
						end: 'hh:mm A',
					},
					{
						shiftId: null,
						shiftDay: 'Thursday',
						start: 'hh:mm A',
						end: 'hh:mm A',
					},
					{
						shiftId: null,
						shiftDay: 'Friday',
						start: 'hh:mm A',
						end: 'hh:mm A',
					},
					{
						shiftId: null,
						shiftDay: 'Saturday',
						start: 'hh:mm A',
						end: 'hh:mm A',
					},
					{
						shiftId: null,
						shiftDay: 'Sunday',
						start: 'hh:mm A',
						end: 'hh:mm A',
					},
				],
				setAllDayShift: {
					start: 'hh:mm A',
					end: 'hh:mm A',
					isDefaultValueSubmitted: false,
				},
				items: [
					{
						text: 'View',
					},
					{
						text: 'Corporate Branch',
						active: true,
					}
				],
				posts: {
					address: "",
					addressInput: "",
					state: "",
					country: "",
					pincode: "",
					city: "",
					latitude: "",
					longitude: "",
				}
			};
		},
		computed: {},
		mounted: function () {
			this.corporateId = localStorage.getItem('corporateId')
			if (this.$route.params.type == 'edit') {
				this.branchId = this.$route.params.branchId;
				this.getCorporateBranch()
			} else {
				this.branchId = 0;
			}
		},
		methods: {
			saturdayOff: function (text) {
				this.isSaturdayOff = text == 'Yes' ? true : false
			},
			sundayOff: function (text) {
				this.isSundayOff = text == 'Yes' ? true : false
			},
			getCorporateBranch: function () {
				this.axios.post('corporate/get-branch-details', {
					branchId: this.branchId
				}).then((result) => {
					this.corporateBranchName = result.data.data.name
					this.corporateBranchAddress = result.data.data.address
					this.posts.addressInput = result.data.data.address
					this.isSaturdayOff = result.data.data.saturdayOff
					this.isSundayOff = result.data.data.sundayOff
					this.shiftName = result.data.data.shifts[0].shiftName
					for (let i = 0;i < result.data.data.shifts.length;i++) {
						this.shifts[i].shiftId = result.data.data.shifts[i]._id
						//this.shifts[i].shiftDay = result.data.data.shifts[i].day
						this.shifts[i].start = this.$moment(result.data.data.shifts[i].startTime).format('hh:mm A')
						this.shifts[i].end = this.$moment(result.data.data.shifts[i].endTime).format('hh:mm A')
					}
				}).catch((error) => {
					console.log(error)
				});
			},
			addCorporateBranch: function () {
				if (this.branchId != 0) {
					let shifts = [];
					for (let i = 0;i < this.shifts.length;i++) {
						if (this.shifts[i].start != 'hh:mm A' || this.shifts[i].end != 'hh:mm A') {
							shifts[i] = {
								shiftName: this.shiftName,
								shiftDay: this.shifts[i].shiftDay,
								start: this.shifts[i].start,
								end: this.shifts[i].end,
							}
						}
					}
					this.axios.post('corporate/edit-branch', {
						corporateBranchId: this.branchId,
						name: this.corporateBranchName,
						address: this.posts.addressInput,
						saturdayOff: this.isSaturdayOff,
						sundayOff: this.isSundayOff,
						location: {
							coordinates: [
								this.posts.latitude,
								this.posts.longitude
							],
						},
						shifts: shifts,
					}).then((result) => {
						let statusCode = result.data.responseCode;
						if (statusCode == 200) {
							this.$router.push('/corporate/corporate-branch-lists');
						}
						if (statusCode === 400) {
							this.$toast.error(result.data.response, {
								position: "top-right",
								timeout: 5000,
								closeOnClick: true,
								pauseOnFocusLoss: true,
								pauseOnHover: true,
								draggable: true,
								draggablePercent: 0.6,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: "button",
								icon: true,
								rtl: false
							})
						}
					}).catch((error) => {
						alert(error);
					});
				} else {
					let shifts = [];
					for (let i = 0;i < this.shifts.length;i++) {
						if (this.shifts[i].start != 'hh:mm A' || this.shifts[i].end != 'hh:mm A') {
							shifts[i] = {
								shiftName: this.shiftName,
								shiftDay: this.shifts[i].shiftDay,
								start: this.shifts[i].start,
								end: this.shifts[i].end,
							}
						}
					}
					this.axios.post('corporate/add-branch', {
						corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
						name: this.corporateBranchName,
						address: this.posts.addressInput != '' ? this.posts.addressInput : this.corporateBranchAddress,
						saturdayOff: this.isSaturdayOff,
						sundayOff: this.isSundayOff,
						location: {
							coordinates: [
								this.posts.latitude,
								this.posts.longitude
							],
						},
						shifts: shifts,
					}).then((result) => {
						let statusCode = result.data.responseCode;
						if (statusCode === 200) {
							this.$router.push('/corporate/corporate-branch-lists');
						}
						if (statusCode === 400) {
							this.$toast.error(result.data.response, {
								position: "top-right",
								timeout: 5000,
								closeOnClick: true,
								pauseOnFocusLoss: true,
								pauseOnHover: true,
								draggable: true,
								draggablePercent: 0.6,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: "button",
								icon: true,
								rtl: false
							})
						}
					}).catch((error) => {
						alert(error);
					});
				}
			},
			setAllTime: function () {
				if (this.setAllTime) {
					for (let i = 0;i < this.shifts.length;i++) {
						if (this.isSaturdayOff && this.shifts[i].shiftDay === 'Saturday') {
							this.shifts[i].start = 'hh:mm A'
							this.shifts[i].end = 'hh:mm A'
						}
						else if (this.isSundayOff && this.shifts[i].shiftDay === 'Sunday') {
							this.shifts[i].start = 'hh:mm A'
							this.shifts[i].end = 'hh:mm A'
						}
						else {
							this.shifts[i].start = this.setAllDayShift.start
							this.shifts[i].end = this.setAllDayShift.end
						}
					}
				}
			},
			getAddressData: function (addressData, placeResultData) {
				//this.corporateBranchAddress = addressData;
				console.log(this.corporateBranchAddress)
				if (placeResultData) {
					this.getAllFields = helperFile.getAddressFieldsFromPlacesApi
					this.getAllFields(placeResultData)
					this.posts.addressInput = placeResultData.formatted_address
					this.posts.latitude = placeResultData.geometry.location.lat()
					this.posts.longitude = placeResultData.geometry.location.lng()
				}
			},
			updateAddress: function(address) {
				this.corporateBranchAddress = address
			},
		},
	};
</script>
<style scoped>
	.btn,
	.btn:active,
	.btn:focus {
		outline: none !important;
		box-shadow: none !important;
	}
	.timing span.to-text {
		margin-left: 20px;
		margin-right: 20px;
	}
	textarea {
		resize: none;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Add Corporate Branch</h4>
						<div class="row">
							<div class="col-lg-6">
								<div class="form-group mb-4">
									<label for="branch-name" class="col-md-3 col-form-label">Name</label>
									<div class="col-md-12">
										<b-form-input
											type="text"
											id="branch-name"
											name="branch-name"
											class="form-control"
											:class="{ 'is-invalid': $v.corporateBranchName.$error }"
											v-model="corporateBranchName"
											placeholder="e.g. Thane branch" />
										<div v-if="$v.corporateBranchName.$error" class="invalid-feedback">
											<span v-if="!$v.corporateBranchName.required">Enter branch name</span>
										</div>
									</div>
								</div>
							</div>
							<!--<div class="col-lg-6">
								<div class="form-group mb-4">
									<label for="location" class="col-md-3 col-form-label">Location</label>
									<div class="col-md-12">
										<input type="location" id="location" class="form-control" v-model="corporateBranchLocation" placeholder="e.g. 19.2, 72.8" />
									</div>
								</div>
							</div>-->
							<div class="col-lg-6">
								<div class="form-group mb-4">
									<label for="branch-address" class="col-md-3 col-form-label">Address</label>
									<div class="col-md-12">
										<vue-google-autocomplete ref="address" id="map" classname="form-control"
											placeholder="Please type your address" v-on:placechanged="getAddressData"
											v-model="corporateBranchAddress" autocomplete="off" :class="{
												'is-invalid': $v.corporateBranchAddress.$error,
											}" />
											<div v-if="$v.corporateBranchAddress.$error" class="invalid-feedback">
												<span v-if="!$v.corporateBranchAddress.required">Enter address</span>
											</div>
										<!--<textarea id="branch-address" class="form-control" v-model="corporateBranchAddress" placeholder="e.g. 103 Shreya House, Andheri Kurla Road, Saki Naka, Mumbai, Maharashtra, 400072"></textarea>-->
									</div>
								</div>
							</div>
							<div class="col-lg-3">
								<div class="form-group mb-4">
									<label class="col-md-12 col-form-label">Saturday Off</label>
									<div class="col-md-12">
										<div class="check-group btn-group-toggle btn-group bv-no-focus-ring"
											style="height: auto;" role="group">
											<button type="button" class="btn btn-outline-primary"
												:class="`${isSaturdayOff ? 'active' : ''}`"
												v-on:click="saturdayOff('Yes')">Yes</button>
											<button type="button" class="btn btn-outline-primary"
												:class="`${!isSaturdayOff ? 'active' : ''}`"
												v-on:click="saturdayOff('No')">No</button>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-3">
								<div class="form-group mb-4">
									<label class="col-md-12 col-form-label">Sunday Off</label>
									<div class="col-md-12">
										<div class="check-group btn-group-toggle btn-group bv-no-focus-ring"
											style="height: auto;" role="group">
											<button type="button" class="btn btn-outline-primary"
												:class="`${isSundayOff ? 'active' : ''}`"
												v-on:click="sundayOff('Yes')">Yes</button>
											<button type="button" class="btn btn-outline-primary"
												:class="`${!isSundayOff ? 'active' : ''}`"
												v-on:click="sundayOff('No')">No</button>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-12">
								<div class="form-group mb-4">
									<label for="shift-name" class="col-md-6 col-form-label">Shift Name</label>
									<div class="col-md-6">
										<input
											id="shift-name"
											type="text"
											class="form-control"
											v-model="shiftName"
											:class="{ 'is-invalid': $v.shiftName.$error }"
											placeholder="Day / Afternoon / Night / UK / US / Australia" />
										<div v-if="$v.shiftName.$error" class="invalid-feedback">
											<span v-if="!$v.shiftName.required">Enter the shift</span>
										</div>
									</div>
								</div>
								<div class="form-group mb-4 col-md-7">
									<label class="com-md-9 col-form-label">Shift Time</label>
									<div class="col-md-12 timing set-all d-flex align-items-center mb-2 justify-content-between">
										<div class="col-md-3">
											<b-form-checkbox type="checkbox" id="check-all" v-model="isTimeForAll" buttons>Set time for all week</b-form-checkbox>
											<!-- <b-form-checkbox-group
												id="check-all"
												:options="checkboxOption"
												v-model="isTimeForAll"
												buttons
												name="button-1"
												button-variant="primary" /> -->
										</div>
										<div class="d-flex col-md-9">
											<vue-timepicker class="col-md-3" format="hh:mm A" v-model="setAllDayShift.start"
												placeholder="Select Start Time" hour-label="Hour" minute-label="Miniutes"
												apm-label="AM/PM" :minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" v-on:change="setAllTime"
												:disabled="!!!isTimeForAll" />
											<span class="to-text">To</span>
											<vue-timepicker class="col-md-3" format="hh:mm A" v-model="setAllDayShift.end"
												placeholder="Select End Time" hour-label="Hour" minute-label="Miniutes"
												apm-label="AM/PM" :minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" v-on:change="setAllTime"
												:disabled="!!!isTimeForAll" />
										</div>
									</div>
									<div v-for="shift in shifts" :key="shift.shiftId" class="col-md-12 d-flex timing mb-2">
										<label for="" class="col-md-3">{{ shift.shiftDay }}</label>
										<div v-if="shift.shiftDay === 'Saturday'" class="d-flex col-md-9">
											<vue-timepicker class="col-md-3" format="hh:mm A"
												v-model="shift.start" placeholder="Select Start Time"
												hour-label="Hour" minute-label="Miniutes" apm-label="AM/PM"
												:minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" :disabled="!!isSaturdayOff || !!isTimeForAll" />
											<span class="to-text">To</span>
											<vue-timepicker class="col-md-3" format="hh:mm A"
												v-model="shift.end" placeholder="Select End Time"
												hour-label="Hour" minute-label="Miniutes" apm-label="AM/PM"
												:minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" :disabled="!!isSaturdayOff || !!isTimeForAll" />
										</div>
										<div v-else-if="shift.shiftDay === 'Sunday'" class="d-flex col-md-9">
											<vue-timepicker class="col-md-3" format="hh:mm A"
												v-model="shift.start" placeholder="Select Start Time"
												hour-label="Hour" minute-label="Miniutes" apm-label="AM/PM"
												:minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" :disabled="!!isSundayOff || !!isTimeForAll" />
											<span class="to-text">To</span>
											<vue-timepicker class="col-md-3" format="hh:mm A"
												v-model="shift.end" placeholder="Select End Time"
												hour-label="Hour" minute-label="Miniutes" apm-label="AM/PM"
												:minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" :disabled="!!isSundayOff || !!isTimeForAll" />
										</div>
										<div v-else class="d-flex col-md-9">
											<vue-timepicker class="col-md-3" format="hh:mm A"
												v-model="shift.start" placeholder="Select Start Time"
												hour-label="Hour" minute-label="Miniutes" apm-label="AM/PM"
												:minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" :disabled="!!isTimeForAll" />
											<span class="to-text">To</span>
											<vue-timepicker class="col-md-3" format="hh:mm A"
												v-model="shift.end" placeholder="Select End Time"
												hour-label="Hour" minute-label="Miniutes" apm-label="AM/PM"
												:minute-interval="15" close-on-complete hide-clear-button
												drop-direction="auto" :disabled="!!isTimeForAll" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<button type="submit" class="col-3 m-2 btn btn-themeYellow w-md waves-effect waves-light"
							:disbaled="loading" @click="addCorporateBranch">Submit</button>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>